
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.bottomSection {
}
.bottomSectionWrapper {
    background-color: #f4f4f4;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #444b53;
}
.title {
    margin: 0;
    padding-bottom: 15px;
}
.brief {
    margin: 0;
}

.button {
    margin: 50px auto;
    font-weight: 700;
    color: white;
    min-width: 280px;
    display: flex;
    background-color: #be1e20;
    border-radius: 16px;
    padding: 15px 20px;
    font-size: 16px;
    max-height: 54px;
    justify-content: center;
    @include md {
        min-width: 600px;
    }

    text-transform: capitalize;
    @media screen and (max-width: 414px) {
        width: 90%;
    }
    svg {
        margin-left: 10px;
    }
}
